body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: aliceblue;

}

.bg-dark {
  background-color: aliceblue;
}
.my-nav {
  color: rgb(14, 7, 7);
  font-size: medium;
  font-style:italic;
}

.container {
  width: 75%;
}

.navigation {
  width: 75%;
}

/* div {
  border:solid;
} */

a {
  color: rgba(12, 1, 1, 0.805);
  font-size: medium;
  font-style:italic;
}


.testStyle {
  color: rgb(0, 0, 0);
  font-size: medium;
  font-style:italic;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .navigation {
  background-color: #f05f5f!important;
} */

h1 {color: rgb(158, 202, 255);}

/* a {
  text-decoration: none;
  color: black;
} */

a:hover {
  text-decoration: none;
  color:  #121111!important;
  background-color: none;
}

/* td, th {
  border: 1px solid #ddd;
  padding: 8px;
} */